<template>
    <el-dialog v-loading="saving" title="发布供给信息-商标" center :visible.sync="show" width="980px">
        <el-form
            :model="formData"
            :rules="rules"
            ref="form"
            label-width="200px"
            label-position="right"
            size="small"
            inline
        >
            <div class="content">
                <el-form-item prop="name" label="商标名称">
                    <el-input v-model="formData.name" placeholder="请输入专利名称"></el-input>
                </el-form-item>
                <el-form-item prop="applyTime" label="申请日期">
                    <el-date-picker
                        v-model="formData.applyTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择"
                    >
                    </el-date-picker>
                </el-form-item>
            </div>
            <div class="content">
                <el-form-item prop="code" label="商标申请号">
                    <el-input v-model="formData.code" placeholder="例如：201620324442.1"></el-input>
                </el-form-item>
                <el-form-item prop="logoType" label="商标类型">
                    <el-select
                        v-model="formData.logoType"
                        clearable
                        filterable
                        placeholder="请选择"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in logoTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            placeholder="请选择"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <el-form-item prop="category" label="所属分类">
                <el-select v-model="formData.category" clearable filterable placeholder="请选择" style="width: 100%">
                    <el-option
                        v-for="item in categoryOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        placeholder="请选择"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="block" prop="servicesItem" label="核定使用商品/服务项目">
                <el-select
                    v-model="formData.servicesItem"
                    multiple
                    class="suffix"
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请输入使用商品/服务项目，按回车分割"
                    clearable
                    popper-class="hideSelect"
                >
                </el-select>
            </el-form-item>
            <el-form-item class="block" prop="owner" label="商标注册人">
                <!-- <el-input v-model="formData.owner"></el-input> -->
                <el-select
                    v-model="formData.owner"
                    class="suffix"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请输入商标注册人，按回车分割"
                    clearable
                    popper-class="hideSelect"
                >
                </el-select>
            </el-form-item>
            <el-form-item prop="img" class="block" label="商标附图">
                <multi-upload class="imgBox" v-model="formData.img" :limit="6">
                    请上传产品主图片，格式JPG或PNG，尺寸500x500px，大小不超过1M，最多可上传6张
                </multi-upload>
            </el-form-item>
            <el-form-item class="block" prop="tradingMethodId" label="交易方式">
                <el-radio-group v-model="formData.tradingMethodId">
                    <el-radio v-for="item in tradingMethodOption" :key="item.value" :label="item.value">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <div class="content">
                <el-form-item prop="expectedPrice" label="期望价（万元）">
                    <el-input-number
                        v-model="formData.expectedPrice"
                        :disabled="formData.negotiateDirectly"
                        @change="Price"
                        :precision="1"
                        :controls="false"
                        placeholder="请输入"
                        style="margin-right: 10px"
                    >
                    </el-input-number>
                    <el-checkbox @change="Directly" v-model="formData.negotiateDirectly">面议</el-checkbox>
                </el-form-item>
                <el-form-item prop="contact" label="联系人">
                    <el-input v-model="formData.contact"></el-input>
                </el-form-item>
            </div>
            <div class="content">
                <el-form-item prop="basePrice" label="底价（万元）">
                    <el-input-number
                        v-model="formData.basePrice"
                        :min="0"
                        :precision="1"
                        placeholder="请输入"
                        :controls="false"
                    >
                    </el-input-number>
                </el-form-item>
                <el-form-item prop="phone" label="联系电话">
                    <el-input v-model="formData.phone"></el-input>
                </el-form-item>
            </div>
            <div class="content">
                <el-form-item prop="commissionType" label="佣金类型">
                    <el-radio-group v-model="formData.commissionType">
                        <el-radio v-for="item in commissionTypeOptions" :key="item.value" :label="item.value">
                            {{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="email" label="邮箱">
                    <el-input v-model="formData.email"></el-input>
                </el-form-item>
            </div>
            <el-form-item
                prop="commission"
                :label="`佣金${formData.commissionType == 'COMMISSION' ? '（万元）' : '（%）'}`"
            >
                <el-input-number
                    v-model="formData.commission"
                    :min="0"
                    :precision="1"
                    :controls="false"
                    placeholder="请输入"
                >
                </el-input-number>
            </el-form-item>

            <el-form-item class="block" prop="address" label="所在地区">
                <el-input class="suffix" v-model="formData.address" placeholder="请输入所在地址"></el-input>
            </el-form-item>
            <!-- <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary">
                            保存
                        </el-button>
                        <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button>
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item> -->
        </el-form>

        <div class="btns">
            <el-button type="info" size="small" @click="show = false">返回</el-button>
            <el-button type="primary" size="small" @click="onSave">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { logoTypeOptions, tradingMethodOption, commissionTypeOptions, phonePattern } from '../../utils/variables';
import { mapState } from 'vuex';
export default {
    name: 'LogoEdit',
    created() {
        this.$http
            .post('/setting/byFlag', { flag: 2 })
            .then(res => {
                if (res.length > 0) {
                    res.forEach(item => {
                        this.categoryOptions.push({
                            label: item.name,
                            value: item.id
                        });
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
    },
    data() {
        var validatePrice = (rule, value, callback) => {
            if (!this.formData.negotiateDirectly) {
                if (!this.formData.expectedPrice) {
                    callback(new Error('请选择期望价方式'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        };
        return {
            needChange: true,
            show: false,
            saving: false,
            formData: {
                servicesItem: [],
                commissionType: 'COMMISSION'
            },
            rules: {
                name: [{ required: true, message: '请输入商标名称' }],
                code: [{ required: true, message: '请输入商标申请号' }],
                // logoType: [{ required: true, message: '请选择商标类型' }],
                // category: [{ required: true, message: '请选择所属分类' }],
                // servicesItem: [{ required: true, message: '请输入使用商品/服务项目' }],
                // owner: [{ required: true, message: '请输入商标注册人' }],
                // img: [{ required: true, message: '请上传商标图样' }],
                // tradingMethodId: [{ required: true, message: '请选择交易方式' }],
                // expectedPrice: [{ required: true, validator: validatePrice, message: '请选择期望价方式' }],
                // basePrice: [{ required: true, message: '请输入底价', trigger: 'blur' }],
                // contact: [{ required: true, message: '请输入联系人' }],
                // phone: { required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' },
                // commissionType: [{ required: true, message: '请选择佣金类型' }],
                // email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                // commission: [{ required: true, message: '请输入佣金', trigger: 'blur' }],
                // address: [{ required: true, message: '请输入所在地区' }]
            },
            logoTypeOptions,
            categoryOptions: [],
            tradingMethodOption,
            commissionTypeOptions,
            inputValue: ''
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        Price() {
            if (this.formData.expectedPrice) {
                this.formData.negotiateDirectly = false;
            }
        },
        Directly() {
            if (this.formData.negotiateDirectly == true) {
                this.$set(this.formData, 'expectedPrice', undefined);
            }
            this.$forceUpdate();
        },
        init() {
            this.show = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                this.formData = {
                    userId: this.userInfo.id,
                    servicesItem: [],
                    owner: [],
                    // tradingMethod: 'TRANSFER',
                    // commissionType: 'COMMISSION',
                    negotiateDirectly: false,
                    contact: this.userInfo.nickname,
                    phone: this.userInfo.phone,
                    email: this.userInfo.email
                };
            });
        },
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                    setTimeout(() => {
                        this.$confirm('发布成功，正在等待平台审核', '提示', {
                            confirmButtonText: '继续发布',
                            cancelButtonText: '退出',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(() => {
                                this.init();
                            }, 1000);
                        });
                        // .catch(() => {
                        //     this.show = false;
                        // });
                    }, 1000);
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData, status: 'PENDING' };
            // console.log(data);
            data.owner = data.owner.join(',');
            this.saving = true;
            console.log("dfaeaff",data)
            this.$http
                .post('/logo/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.show = false;
                    this.$emit('refreash');
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/logo/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        },
        handleClose(tag) {
            this.formData.servicesItem.splice(this.formData.servicesItem.indexOf(tag), 1);
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.formData.servicesItem.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        }
    }
};
</script>
<style lang="less" scoped>
.el-tag {
    margin-bottom: 11px;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.input-new-tag {
    width: 100%;
    vertical-align: bottom;
}
.imgBox {
    width: 600px !important;
}
.suffix {
    width: 400px !important;
    /deep/ .el-input__suffix {
        opacity: 0;
    }
}
/deep/ .el-checkbox__inner {
    border-radius: 50%;
}
.content {
    display: flex;
}
/deep/ .el-dialog__header {
    padding: 17px 0px !important;
}
/deep/ .el-form {
    width: 100% !important;
    margin: 0px !important;
}
</style>
