<template>
    <filter-list
        v-model="list"
        :sortKey="sortKey"
        ref="list"
        url="/logo/all"
        :filters="filters"
        @btnEvent="addInfo"
        @init="init"
    >
        <template v-for="item in list">
            <logo-row :key="item.id" :info="item"></logo-row>
        </template>
        <logo-add ref="public" @refreash="refreash"></logo-add>
    </filter-list>
</template>

<script>
import FilterList from '../../components/page/FilterList.vue';
import { logoTypeOptions, tradingMethodOption, commissionTypeOptions, moneyOptions } from '../../utils/variables';
import LogoRow from '../../components/list/LogoRow.vue';
import LogoAdd from '../../components/popup/LogoAdd.vue';
export default {
    components: { FilterList, LogoRow, LogoAdd },
    data() {
        return {
            logoTypeOptions,
            tradingMethodOption,
            commissionTypeOptions,
            moneyOptions,
            categoryOptions: [], //分类,
            list: [],
            sortKey: [
                // {
                //     name: '发布时间',
                //     key: 'createdAt'
                // },
                // {
                //     name: '价格区间',
                //     key: 'expectedPrice'
                // },
                {
                    name: '浏览量',
                    key: 'view'
                }
            ]
        };
    },
    created() {
        this.init();
    },
    computed: {
        filters() {
            return [
                {
                    name: '商标类型',
                    key: 'logoType',
                    list: [...this.logoTypeOptions]
                },
                {
                    name: '所属分类',
                    key: 'category',
                    list: [...this.categoryOptions]
                },
                {
                    name: '交易方式',
                    key: 'tradingMethodId',
                    list: [...this.tradingMethodOption]
                }
                // {
                //     name: '价格区间',
                //     key: 'expectedPrice',
                //     list: [...this.moneyOptions]
                // },
                // {
                //     name: '发布时间',
                //     key: 'createdAt',
                //     list: [...this.createdAtOptions]
                // }
            ];
        },
        createdAtOptions() {
            return [
                {
                    label: '一周内',
                    value: this.getTimeBefor()
                },
                {
                    label: '一月内',
                    value: this.getTimeOnem()
                },
                {
                    label: '二月内',
                    value: this.getTimeTom()
                },
                {
                    label: '三月内',
                    value: this.getTimeTherrm()
                },
                {
                    label: '三月以上',
                    value: this.getTimeTherrms()
                }
            ];
        }
    },
    methods: {
        init() {
            this.$http
                .post('/setting/byFlag', { flag: 2 })
                .then(res => {
                    if (res.length > 0) {
                        this.categoryOptions = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });
                    }

                    this.$nextTick(() => {
                        this.$refs.list.init();
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        },
        addInfo(value) {
            if (value) {
                // this.$refs.public.init();
                this.checkLogin(false).then(() => {
                    this.$refs.public.init();
                });
            }
        },
        refreash() {
            this.$refs.list.getData();
        }
    }
};
</script>

<style lang="less" scoped></style>
